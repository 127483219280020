import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "prerequisites"
    }}>{`Prerequisites`}</h1>
    <hr></hr>
    <h2 {...{
      "id": "languages"
    }}>{`Languages`}</h2>
    <p>{`R.A.D. Routes are currently able to be created, saved, excuted, edited and ran in 3 languages.`}</p>
    <ul>
      <li parentName="ul">{`Python 3`}</li>
      <li parentName="ul">{`Ruby`}</li>
      <li parentName="ul">{`Node.js`}</li>
    </ul>
    <p>{`Python 3 is required to install and run the base R.A.D. Routes Runner, which most many Linux distributions have, and MacOS has by default.`}</p>
    <p>{`Most distributions of Linux, and MacOS come pre-installed with Python 3, and Ruby, but you will need to have Node.js already installed or install it.`}</p>
    <p>{`If you need any of the availible R.A.D. Languages, follow the links below for installation.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.python.org/downloads/"
          }}>{`Download and install the latest version of Python 3 here`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.ruby-lang.org/en/downloads/"
          }}>{`Download and install the latest version of Ruby here`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://nodejs.org/en/download/"
          }}>{`Download and install the latest version of Node.js here`}</a></p>
      </li>
    </ul>
    <h2 {...{
      "id": "radroutescom"
    }}>{`RADRoutes.com`}</h2>
    <h3 {...{
      "id": "web-platform"
    }}>{`Web Platform`}</h3>
    <hr></hr>
    <p>{`To make a R.A.D. Route on `}<a parentName="p" {...{
        "href": "https://radroutes.com"
      }}>{`RADRoutes.com`}</a>{`, you simply need `}<a parentName="p" {...{
        "href": "https://radroutes.com/Register"
      }}>{`register for a R.A.D. Routes account`}</a>{`, after confirming your email address; you will be able to start uploading and editing your own R.A.D. Routes!`}</p>
    <h2 {...{
      "id": "rad-route-runner"
    }}>{`R.A.D. Route Runner`}</h2>
    <h3 {...{
      "id": "cli"
    }}>{`CLI`}</h3>
    <hr></hr>
    <p>{`To run any public, or private R.A.D. Route; you will need to `}<a parentName="p" {...{
        "href": "https://radroutes.com/Downloads"
      }}>{`download the R.A.D. Routes Runner`}</a></p>
    <p>{`At the moment the R.A.D. Routes CLI Runner has been tested and is working on many Linux distributions, and MacOS. `}</p>
    <p>{`You need to have Python 3 installed, which most many Linux distributions have, and MacOS has by default.`}</p>
    <hr></hr>
    <hr></hr>
    <h1 {...{
      "id": "rad-route-runner-installation"
    }}>{`R.A.D. Route Runner Installation`}</h1>
    <h2 {...{
      "id": "macos-app-installation"
    }}>{`MacOS App Installation`}</h2>
    <hr></hr>
    <p>{`To install via a zip file, `}<a parentName="p" {...{
        "href": "https://radroute.run/downloads/maczip"
      }}>{`download the zip here`}</a></p>
    <h2 {...{
      "id": "macos-cli-installation"
    }}>{`MacOS CLI Installation`}</h2>
    <hr></hr>
    <p>{`To Install via the CLI terminal, simply copy and paste the command below into your terminal;`}</p>
    <pre><code parentName="pre" {...{}}>{`curl -s https://raw.githubusercontent.com/Rad-Routes/rad_routes_cli_installer/master/mac_installers/auto_mac_installer.sh | bash
`}</code></pre>
    <p>{`The code above will download the R.A.D. Routes installer shell script, and then execute in bash.`}</p>
    <h2 {...{
      "id": "linux-cli-installation"
    }}>{`Linux CLI Installation`}</h2>
    <hr></hr>
    <p>{`Currently, the R.A.D. Routes CLI Runner is written in Python 3, so you will need any Python 3.xx Installalled, which many Linux distributions come - with.
To Install via the CLI terminal, simply copy and paste the command below into your terminal;`}</p>
    <pre><code parentName="pre" {...{}}>{`curl -s https://raw.githubusercontent.com/Rad-Routes/rad_routes_cli_installer/master/mac_installers/auto_mac_installer.sh | bash
`}</code></pre>
    <p>{`The code above will download the R.A.D. Routes installer shell script, and then execute in bash.`}</p>
    <h2 {...{
      "id": "post-installation"
    }}>{`Post Installation`}</h2>
    <hr></hr>
    <p>{`After you have installed via the zip App on MacOS, or the curl/bash script in MacOS or Linux, your terminal will have access to the rr command alias which is short for Rad Routes, and you can check help and see availible usage with the command below;`}</p>
    <pre><code parentName="pre" {...{}}>{`rr -h
`}</code></pre>
    <p>{`or`}</p>
    <pre><code parentName="pre" {...{}}>{`rr help
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      